import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { BASE_BREADCRUMBS } from '../constants'
import { useHtmlParser } from '../hooks/useHtmlParser'

const PrivacyPolicyPage = ({ data }) => {
  let privacyPolicy = data.allPrivacyPolicy.nodes[0]?.content
  return (
    <Layout
      pageTitle="Privacy Policy"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/privacy-policy", name: "Privacy Policy" }]}
    >
      <Seo
        title="Privacy Policy | Typenex Medical"
        description="View our Privacy Policy, and how we protect our clients information"
      />
      <div>
        {useHtmlParser(privacyPolicy)}
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    allPrivacyPolicy {
      nodes {
        content
      }
    }
  }
`
